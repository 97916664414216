$headerColor:red;
$ff:rgba(31, 30, 30, 0.397);

$themeBackground:#AA4149;
$whiteBackground:#fff;
$sds:#d8848b5d;


//New Color set
$primaryColor:#9D1D27;
$primaryBorder:#CC837C;

$textColor_1:#3D3D3D;
$textColor_2:#168CC6;
$textColor_3:#219FCD;
$textColor_4:#C21F2C;
$textColor_5:#8D8D8D;
$textColor_6:#2884DE;

$layoutBackground:#F4F5F5;
$backgroundSkin_1:#EBD3D3;
$backgroundSkin_2:#F9E9E9;
$backgroundSkin_3:#D7F4FA;
$backgroundSkin_4:#F3F2F1;
$backgroundSkin_5:#21740D;
$backgroundSkin_6:#B5D2ED;  //Used behind the tabs of Admin credit queue

$borderColor_1:#84CFE1;
$borderColor_2:#21740D;
$borderColor_3:#333333;

.colorUnhover{
color:#000000;
}
.colorUnhover:hover{
    color:#2884DE;
}
.colorhover{
color:#2884DE;
}