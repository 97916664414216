@media screen and (min-width:320px) and (max-width:680px){
  #Wallet_Screen{
      display: none;
  }
  #lengthdefine1{
    width: 103%;
  }
  #Ipad_Screen{
      display: contents;
  }
  #rowPad{
    width:100%;
    margin-left:3%;
    margin-top:2rem;
  }
  #main_body{
    width: 100%;
  }
  #selectButton{
    margin-left: 3%;
  }

  #mySidenav a {
      position: absolute;
      left: -315px;
      transition: 0.3s;
      padding: 15px;
      width: 350px;
      text-decoration: none;
      font-size: 15px;
      color: white;
      border-radius: 0 5px 5px 0;
      margin-top: 9rem;
  }
  
  #mySidenav a:hover {
      left: 0;
  }

    
    #about {
      top: 20px;
      background-color: #04AA6D;
    }
    
    #blog {
      top: 80px;
      background-color: #2196F3;
    }
    
    #projects {
      top: 140px;
      background-color: #f44336;
    }
    
    #contact {
      top: 200px;
      background-color: #555
    }
    
}
@media screen and (min-width:681px) and (max-width:800px){
    #Wallet_Screen{
        display: none;
    }
    #lengthdefine1{
      width: 99%;
    }
    #rowPad{
      width:97%;
      margin-left:2%;
      margin-top:2rem;
    }
    #Ipad_Screen{
        display: contents;
    }
    #main_body{
      width: 124%;
    }
    #selectButton{
      margin-left: 30%;
    }

    #mySidenav a {
        position: absolute;
        left: -315px;
        transition: 0.3s;
        padding: 15px;
        width: 350px;
        text-decoration: none;
        font-size: 15px;
        color: white;
        border-radius: 0 5px 5px 0;
        margin-top: 9rem;
    }
    
    #mySidenav a:hover {
        left: 0;
    }

      
      #about {
        top: 20px;
        background-color: #04AA6D;
      }
      
      #blog {
        top: 80px;
        background-color: #2196F3;
      }
      
      #projects {
        top: 140px;
        background-color: #f44336;
      }
      
      #contact {
        top: 200px;
        background-color: #555
      }
      
}
@media screen and (min-width:801px){
    #Wallet_Screen{
        display: contents;
    }
    #Ipad_Screen{
        display: none;
    }
}